<template>
  <CCard spacing>
    <CTypo tstyle="title3">
      {{ $t('displaySettings') }}
    </CTypo>
    <CTypo tstyle="footnote2" class="text-gray-500">
      {{ $t('shops.customizeLayout') }}
    </CTypo>
    <CForm
      v-if="!loading"
      class="mt-4"
      @submit="onSubmit"
      :error-fields-response="errorFieldsResponse"
      :loading="submitLoading"
    >
      <CTextField
        v-model="form.cardTitle"
        :label="$t('title')"
      />
      <CTextField
        v-model="form.cardLine1"
        :label="$t('list')"
      />
      <CTextField
        v-model="form.cardLine2"
      />
      <CTextField
        v-model="form.cardLine3"
      />
      <CTypo tstyle="subheadline">
        {{ $t('logo') }}
      </CTypo>
      <CImageUpload
        v-model="logoUpload"
        :label="$t('shops.uploadLogo')"
        :stencil-props="{
          aspectRatio: null,
        }"
        class="mb-4"
        preview-image-class="w-[240px] bg-prevprimary p-6"
        modal-variant="formsheet"
        :async-reset="logoFromServer"
        no-cropper
        @async-reset="onLogoReset"
        @change="onLogoFileInput"
      />
      <CMessage v-if="logoToBig" variant="danger" class="mb-3">
        {{ $t('maxFileSize', { maxFileSize: '3 MB' }) }}
      </CMessage>
      <CTypo tstyle="subheadline">
        {{ $t('coverPicture') }}
      </CTypo>
      <CImageUpload
        v-model="coverPictureUpload"
        :label="$t('shops.uploadCoverPicture')"
        :stencil-props="{
          aspectRatio: null,
        }"
        class="mb-4"
        preview-image-class="w-[500px]"
        modal-variant="formsheet"
        :async-reset="coverPictureFromServer"
        no-cropper
        @async-reset="onCoverPictureReset"
        @change="onCoverPictureFileInput"
      />
      <CMessage v-if="coverPictureToBig" variant="danger" class="mb-3">
        {{ $t('maxFileSize', { maxFileSize: '5 MB' }) }}
      </CMessage>
      <CColorPicker
        v-model="form.color"
        :input-value-type="form.color.indexOf('rgb') ? 'rgb' : 'hex'"
        :label="$t('shops.chooseColor')"
        :message="$t('shops.colorIsUsedForButtonsAndMore')"
        @input="onColorInput"
      />
    </CForm>
    <div v-else class="h-40 flex items-center justify-center">
      <CLoadingSpinner class="text-gray-600 w-6 h-6" />
    </div>
  </CCard>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import { UPDATE_SHOP } from '@/store/actionTypes';
import { SET_SUCCESS_NOTIFICATION } from '@/store/mutationsTypes';

export default {
  data: () => ({
    form: {
      domain: null,
      color: '#0c55a4',
      password: null,
      cardTitle: null,
      cardLine1: null,
      cardLine2: null,
      cardLine3: null,
    },
    logoUpload: null,
    coverPictureUpload: null,
    logoChanged: null,
    coverPictureChanged: null,
    slugUniqueError: false,
    errorFieldsResponse: null,
  }),

  computed: {
    loading() {
      return this.$store.state.shop.loading;
    },
    submitLoading() {
      return this.$store.state.shop.submitLoading;
    },
    logoFromServer() {
      return this.logoUpload && typeof this.logoUpload === 'string';
    },
    coverPictureFromServer() {
      return this.coverPictureUpload && typeof this.coverPictureUpload === 'string';
    },
    shopData() {
      return this.$store.state.shop.shop;
    },
    logoToBig() {
      return !!this.logoUpload && this.logoUpload.size > 3000000;
    },
    coverPictureToBig() {
      return !!this.coverPictureUpload && this.coverPictureUpload.size > 5000000;
    },
    domain() {
      return process.env.VUE_APP_STOREFRONT_BASE.split('[*]')[1];
    },
  },

  watch: {
    shopData: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.logoUpload = newVal.logo;
          this.coverPictureUpload = newVal.coverPicture;
          this.form.domain = newVal.domain;
          this.form.color = `rgb(${newVal.bgColor})`;
          this.form.cardTitle = newVal.cardTitle;
          this.form.cardLine1 = newVal.cardLine1;
          this.form.cardLine2 = newVal.cardLine2;
          this.form.cardLine3 = newVal.cardLine3;
          this.onColorInput(this.form.color);
        }
      },
    },
    form: {
      handler() {
        this.errorFieldsResponse = null;
      },
      deep: true,
    },
    logoChanged: {
      handler() {
        this.errorFieldsResponse = null;
      },
    },
    coverPictureChanged: {
      handler() {
        this.errorFieldsResponse = null;
      },
    },
  },

  methods: {
    ...mapActions([UPDATE_SHOP]),
    ...mapMutations([SET_SUCCESS_NOTIFICATION]),
    async onLogoReset({ onSuccess }) {
      await this.updateShop({
        removeLogo: true,
      });
      onSuccess();
    },
    async onCoverPictureReset({ onSuccess }) {
      await this.updateShop({
        removeCoverPicture: true,
      });
      onSuccess();
    },
    async onSubmit() {
      const payload = {
        active: this.shopData.active,
        cardTitle: this.form.cardTitle,
        cardLine1: this.form.cardLine1,
        cardLine2: this.form.cardLine2,
        cardLine3: this.form.cardLine3,
      };
      if (this.form.color.indexOf('rgb') === -1) {
        payload.color = this.form.color;
      }

      try {
        await this.updateShop(payload);
      } catch (e) {
        if (e.response.data.code === 'E_VALIDATION_FAILURE') {
          this.errorFieldsResponse = e.response;
          return;
        }
        throw e;
      }

      if (this.logoChanged) {
        try {
          await this.updateShop({ logo: this.logoChanged });
        } catch (e) {
          if (e.response.data.code === 'E_VALIDATION_FAILURE') {
            this.errorFieldsResponse = e.response;
            return;
          }
          throw e;
        }
      }
      if (this.coverPictureChanged) {
        try {
          await this.updateShop({ coverPicture: this.coverPictureChanged });
        } catch (e) {
          if (e.response.data.code === 'E_VALIDATION_FAILURE') {
            this.errorFieldsResponse = e.response;
            return;
          }
          throw e;
        }
      }
      this.setSuccessNotification({ content: null, show: true });
    },
    onLogoFileInput(e) {
      this.logoChanged = e;
    },
    onCoverPictureFileInput(e) {
      this.coverPictureChanged = e;
    },
    onColorInput(value) {
      document.documentElement.style.setProperty('--primaryprev', value);
    },
  },
};
</script>
