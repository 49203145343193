<template>
  <CCard spacing>
    <div class="flex items-center justify-between">
      <div>
        <CTypo tstyle="subheadline">
          {{ $t('shops.activate') }}
        </CTypo>
        <CTypo tstyle="footnote2" class="text-gray-500">
          <template v-if="active">
            {{ $t('shops.currentlyActive') }}
          </template>
          <template v-else>
            {{ $t('shops.currentlyInactive') }}
          </template>
        </CTypo>
      </div>
      <div class="inline-flex items-center">
        <div v-if="loading" class="inline-flex items-center text-gray-300 mr-2">
          <CLoadingDots class="h-4" />
        </div>
        <CSwitch v-model="active" :disabled="disabled" @input="sendChange" />
      </div>
    </div>
  </CCard>
</template>

<script>
import { mapActions } from 'vuex';
import { UPDATE_SHOP } from '@/store/actionTypes';

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    active: false,
  }),

  computed: {
    currentlyActive() {
      if (this.$store.state.shop.shop) {
        return this.$store.state.shop.shop.active;
      }
      return false;
    },
  },

  watch: {
    currentlyActive: {
      immediate: true,
      handler(newVal) {
        this.active = newVal;
      },
    },
  },

  methods: {
    ...mapActions([UPDATE_SHOP]),
    sendChange() {
      this.updateShop({
        active: this.active,
      });
    },
  },
};
</script>
