<template>
  <div class="space-y-4">
    <CAlert v-if="unfilledPages.length > 0" variant="warning">
      {{ $tc('unfilledLegalPages', unfilledPages.length) }}
      <span v-for="(page, index) in unfilledPages" :key="page">
        {{ page }}<template v-if="index < unfilledPages.length - 1">,</template>
      </span>.
      <template v-slot:content>
        {{ $t('youCanFindLegalTemplatesOnOurHomepage') }}
      </template>
      <template v-slot:links>
        <CLink href="https://contimo.de/docs/allgemeines/rechtstexte" target="_blank">
          {{ $t('toWebsite') }}
        </CLink>
      </template>
    </CAlert>
    <ActivateCard
      :loading="waitForShopActivation"
    />
    <RepresentationCard />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ActivateCard from '@/ui/components/Merchant/Shops/ActivateCard.vue';
import RepresentationCard from '@/ui/components/Merchant/Shops/RepresentationCard.vue';
import { LEGAL_PAGES, WAIT_FOR_SHOP_ACTIVATION } from '@/store/gettersTypes';
import { GET_PAGES } from '@/store/actionTypes';

export default {
  components: {
    ActivateCard,
    RepresentationCard,
  },

  data: () => ({
    unfilledPages: [],
  }),

  computed: {
    ...mapGetters([WAIT_FOR_SHOP_ACTIVATION, LEGAL_PAGES]),
  },

  watch: {
    legalPages: {
      immediate: true,
      handler() {
        this.checkLegalPagesForFilled();
      },
    },
  },

  methods: {
    ...mapActions([GET_PAGES]),
    checkLegalPagesForFilled() {
      this.unfilledPages = [];
      this.legalPages.forEach((page) => {
        if (!page.content) {
          this.unfilledPages.push(page.title);
        }
        if (Array.isArray(page.content?.content)) {
          page.content.content.forEach((content) => {
            if (!content.content || content.content?.length === 0) {
              this.unfilledPages.push(page.title);
            }
          });
        }
      });
    },
  },
};
</script>
